import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser } from 'actions/user';

import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TimelineIcon from '@mui/icons-material/Timeline';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ZikiSVG from '../../../public/images/zikifm-homepage.svg';
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { Album, Logout, PersonAdd, Search, Settings } from '@mui/icons-material';
import AppreciationProgress from 'components/Appreciation/AppreciationProgress';
import { useAppSelector } from '../../store-init';

const createPageObject = (heading, to, icon) => ({
  heading,
  to,
  icon,
});

const pages = [
  // createPageObject('Search', '/search', Search),
  createPageObject('Artists', '/artists', Album),
  createPageObject('Concerts', '/concerts', Album),
  createPageObject('Merch', '/merch', Album),
  createPageObject('News', '/news', Album),
];

const MainNav = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  const isHomepage = location?.pathname === '/';

  const user = useAppSelector((state) => state.user);
  const appreciation = useAppSelector((state) => state.appreciation);

  useEffect(() => {
    dispatch(getCurrentUser());
  }, []);

  const handleLogout = () => {
    window.location.pathname = '/auth/logout';
  };

  return (
    <Container component="header" maxWidth="xl" sx={{ mb: isHomepage ? 0 : 10 }}>
      <Box
        id="container"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Link
          to="/"
          style={{
            textDecoration: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              color: 'primary.main',
              height: { xs: '80px', md: isHomepage ? '300px' : '80px' },
              width: { xs: '80px', md: isHomepage ? '300px' : '80px' },
            }}
          >
            <ZikiSVG />
          </Box>
          {!isHomepage && (
            <Typography
              variant="h6"
              component="p"
              sx={{
                letterSpacing: 3,
                color: 'white',
              }}
            >
              ZIKI.FM
            </Typography>
          )}
        </Link>
        <Box
          sx={{
            my: 1,
            marginLeft: 'auto',
            gridArea: 'accountnav',
            textAlign: 'right',
            display: 'flex',
            flexDirection: { xs: 'column-reverse', sm: 'row' },
            alignItems: { xs: 'flex-end', sm: 'flex-start' },
            justifyContent: { xs: 'space-between' },
          }}
        >
          {user?.nickname ? (
            <div>
              <Button
                variant="text"
                component={Link}
                to={`/appreciation/${user.nickname}`}
                sx={{ mr: 1 }}
                startIcon={<TimelineIcon />}
                color="secondary"
              >
                <AppreciationProgress
                  min={appreciation?.level?.start}
                  max={appreciation?.level?.end}
                  level={appreciation?.level?.level}
                  value={appreciation?.level?.total}
                  compact
                />
              </Button>
              <Button
                variant="text"
                component={Link}
                to={`/your-favourites`}
                startIcon={<FavoriteIcon />}
              >
                Favourites
              </Button>
            </div>
          ) : (
            <div>
              <Button
                component="a"
                href="/auth/create-account"
                color="secondary"
                startIcon={<TimelineIcon />}
                sx={{ mr: 1 }}
              >
                Get Started
              </Button>
              <Button component="a" href="/auth/login" color="primary" sx={{ mr: 1 }}>
                Login
              </Button>
            </div>
          )}
          <div>
            <Button
              variant="text"
              component={Link}
              to={`/search`}
              startIcon={<Search />}
              sx={{ mr: 1 }}
            >
              Search
            </Button>
            <Button
              aria-controls={open ? 'menu' : undefined}
              aria-label="Menu"
              aria-haspopup="menu"
              onClick={() => setOpen(!open)}
              endIcon={<MenuIcon />}
              variant="outlined"
              size="small"
              sx={{ ml: 2 }}
            >
              <span>Menu</span>
            </Button>
          </div>
          <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
            <List>
              {pages.map(({ heading, to, icon: Icon }) => (
                <ListItem key={heading}>
                  <ListItemButton component={Link} to={to} onClick={() => setOpen(false)}>
                    <ListItemIcon>{<Icon />}</ListItemIcon>
                    <ListItemText primary={heading} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider />
            {user && (
              <List>
                <ListItem>
                  <ListItemButton onClick={() => setOpen(false)} component={Link} to="/settings">
                    <ListItemIcon>
                      <Settings />
                    </ListItemIcon>
                    <ListItemText primary="Settings" />
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton onClick={handleLogout}>
                    <ListItemIcon>
                      <Logout />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                  </ListItemButton>
                </ListItem>
              </List>
            )}
          </Drawer>
        </Box>
      </Box>
    </Container>
  );
};
export default MainNav;
