import { CLEAR_USER, REMOVE_NEW_BADGE, UPDATE_FAVOURITES, LOADING_USER } from '../actions/types';

const initial = {
  loading: false,
  artists: [],
  badges: {},
};

export default (state = initial, action) => {
  switch (action.type) {
    case UPDATE_FAVOURITES:
      const { data, loading } = action;
      return { ...data, loading };
    case REMOVE_NEW_BADGE:
      const { data: badgeId } = action;
      const badges = {
        ...state.badges,
        new: state?.badges?.new?.filter((badge) => badge[1] !== badgeId),
      };
      return { ...state, ...badges };
    case LOADING_USER:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_USER:
      return initial;
    default:
      return state;
  }
};
