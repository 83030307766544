import 'regenerator-runtime/runtime';

import React, { useState, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import CssBaseline from '@mui/material/CssBaseline';
import loadable from '@loadable/component';

import Footer from './components/Footer';
import BadgeNotification from 'components/Badges/BadgeNotification';
import 'index.css';

import ScrollToTop from 'utils/scrollToTop';
import MainNav from './components/MainNav';
import { redirects, routesManifest } from './routesManifest';

const CookieBanner = loadable(() => import('components/CookieBanner'));
const NotFound = loadable(() => import('pages/NotFound'));
const Privacy = loadable(() => import('pages/Privacy'));

const App = () => {
  const [acceptedCookies, setAcceptedCookies] = useState(true);

  const setCookies = (result: boolean) => {
    setAcceptedCookies(result);
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('zcookie', `${result}`);
      if (typeof window !== 'undefined') {
        if (window.gtag && result) {
          window.gtag('consent', 'update', {
            analytics_storage: 'granted',
          });
        } else {
          window.location.reload();
        }
      }
    }
  };

  useEffect(() => {
    if (!['true', '1'].includes(localStorage.getItem('zcookie') || 'false')) {
      // What
      setAcceptedCookies(false);
    }
  }, []);

  return (
    <>
      <CssBaseline />
      <Helmet
        defaultTitle="Music news, concerts, merch and more - all in one place | ZIKI.FM"
        titleTemplate="%s | Music news, concerts, merch and more - all in one place | ZIKI.FM"
      />
      {!acceptedCookies && <CookieBanner cookieFunc={setCookies} />}
      <ScrollToTop />
      <MainNav />
      <main style={{ minHeight: '100vh' }}>
        <Switch>
          <Route
            path="/privacy"
            render={(props) => (
              <Privacy cookies={acceptedCookies} setCookies={setCookies} {...props} />
            )}
            exact
          />
          {redirects.map((route) => (
            <Redirect key={route.id} from={route.from} to={route.to} />
          ))}
          {routesManifest.map((route) => (
            <Route
              key={route.id}
              path={route.path}
              component={route.component}
              exact={route.exact || false}
            />
          ))}
          <Route component={NotFound} />
        </Switch>
      </main>
      <Footer />
      <BadgeNotification />
    </>
  );
};

export default App;
