import React from 'react';
import { Link } from 'react-router-dom';

import { Paper, Button, Typography, Divider } from '@mui/material';

import IconButton from '@mui/material/IconButton';
import MuiLink from '@mui/material/Link';
import { Facebook, Twitter } from 'mdi-material-ui';

import classes from './footer.module.css';

interface ToProps {
  pathname: string;
}

const Footer = () => (
  <footer className={classes.footer}>
    <Divider variant="middle" />
    <Typography className={classes.report}>
      Something wrong with this page?{' '}
      <Button
        component={Link}
        size="small"
        color="error"
        to={({ pathname }: ToProps) => ({
          //<Link to={location => ({ ...location, pathname: "/courses" })} />
          pathname: '/support/report-an-issue',
          state: { pathname },
        })}
      >
        Report an issue
      </Button>
    </Typography>
    <Paper className={classes.footerMain}>
      <div>
        <IconButton
          component="a"
          href="https://www.facebook.com/zikifm"
          aria-label="ZIKIFM Facebook"
          size="large"
        >
          <Facebook />
        </IconButton>
        <IconButton
          component="a"
          href="https://twitter.com/ZikiFm"
          aria-label="ZIKIFM Twitter"
          size="large"
        >
          <Twitter />
        </IconButton>
      </div>
      <Typography align="center">ZIKI.FM &copy; 2019-2023</Typography>
      <Typography>
        <MuiLink component={Link} to="/blog">
          Blog
        </MuiLink>{' '}
        |{' '}
        <MuiLink component={Link} to="/privacy">
          Privacy &amp; Cookies
        </MuiLink>{' '}
        |{' '}
        <MuiLink component={Link} to="/our-mission">
          About ZIKI.FM
        </MuiLink>{' '}
        |{' '}
        <MuiLink component={Link} to="/support">
          Support
        </MuiLink>
      </Typography>
    </Paper>
  </footer>
);

export default Footer;
