export const ADD_FEED = 'add_feed';

// FAVOURITES
export const ADDREMOVE_FAVOURITE = 'addremove_favourite';
export const UPDATE_FAVOURITES = 'update_favourites';

// USER
export const CLEAR_USER = 'clear_user';
export const NEW_BADGES = 'new_badges';
export const REMOVE_NEW_BADGE = 'remove_new_badge';
export const UPDATE_APPRECIATION = 'update_appreciation';
export const USER_PERMISSIONS = 'user_permissions';
export const LOADING_USER = 'loading_user';

// USER SETTINGS
export const SETTINGS_PROCESSING = 'settings_processing';
export const UPDATE_SETTINGS = 'update_settings';

// PLAYLISTS
export const UPDATE_USER_PLAYLISTS = 'update_playlists';
export const PLAYLISTS_LOADING = 'playlists_loading';
export const UPDATE_SETLIST_PLAYLISTS = 'update_setlist_playlists';

// SETLISTS
export const SETLISTS_LOADING = 'setlists_loading';
export const SETLISTS_LOADED = 'setlists_loaded';
export const SETLISTS_MORE = 'setlists_more';

// CHECKOUT
export const CHECKOUT_SECRET = 'checkout_secret';

// PRODUCT AND MERCH
export const GET_PRODUCT = 'get_product';
export const MERCH_LOADED = 'merch_loaded';
export const MERCH_LOADING = 'merch_loading';
export const MERCH_MORE = 'merch_more';
export const SET_IS_AFFILIATE = 'set_is_affiliate';
export const FILTERED_MERCH_LOADING = 'filtered_merch_loading';
export const FILTERED_MERCH_LOADED = 'filtered_merch_loaded';

// HEADING
export const SET_HEADING = 'set_heading';

// SELECTED USER
export const SELECTED_USER = 'selected_user';

// EVENTS
export const EVENTS_LOADING = 'events_loading';
export const EVENTS_LOADED = 'event_loaded';
export const EVENTS_MORE = 'events_more';
export const EVENTS_MAP = 'events_map';

// CONCERTS
export const CONCERT_DETAILS_LOADING = 'concert_details_loading';
export const CONCERT_DETAILS_LOADED = 'concert_details_loaded';
export const CONCERT_ATTENDING_UPDATING = 'concert_attending_updating';
export const CONCERT_ATTENDING_UPDATED = 'concert_attending_updated';
export const GET_USER_CONCERTS = 'get_user_concerts';

// NEWS
export const NEWS_LOADING = 'news_loading';
export const NEWS_LOADED = 'news_loaded';
export const NEWS_MORE = 'news_more';
export const NEWS_FEEDS = 'news_feeds';
export const FEEDS_LOADING = 'feeds_loading';

// ADMIN
export const GET_ADMIN_ARTIST = 'get_admin_artist';

// ARTIST
export const GET_ARTIST_BY_MBID = 'get_artist_by_mbid';
export const GET_ARTIST_MERCH = 'get_artist_merch';
export const ARTIST_LOADING = 'loading_artist';
export const ARTIST_LOADED = 'loaded_artist';

// COMMENTS
export const COMMENTS_LOADING = 'comment_loading';
export const COMMENTS_LOADED = 'comments_loaded';
export const COMMENT_ADDING = 'comment_adding';
export const COMMENT_ADDED = 'comment_added';
export const COMMENT_DELETING = 'comment_deleting';
export const COMMENT_DELETED = 'comment_deleted';
export const COMMENT_REPORTING = 'comment_reporting';
export const COMMENT_REPORTED = 'comment_reported';

// OLD
export const ARTISTUPDATE = 'artistupdate';
export const ERROR_REGISTER = 'error_register';
export const ERROR_LOGIN = 'error_login';
export const FETCH_ADMINFETCHDATA = 'fetch_adminfetchdata';
export const FETCH_EVENTS = 'fetch_events';
export const FETCH_RANDOMARTISTS = 'fetch_randomartists';
export const FETCH_SELECTEDUSER = 'fetch_selecteduser';
export const FETCH_USER = 'fetch_user';
export const SEARCH_ARTISTS = 'search_artists';
export const SPOTIFY_AUTH = 'spotify_auth';
export const SPOTIFY_CALLBACK = 'spotify_callback';
export const SPOTIFY_REFRESH = 'spotify_refresh';
export const SPOTIFY_TOPARTISTS = 'spotify_topartists';
export const INTEGRATE_FACEBOOK = 'integrate_facebook';
export const USER_FAVOURITES = 'user_favourites';

// BLOG
export const BLOG_POSTS_LOADING = 'blog_posts_loading';
export const BLOG_POSTS_LOADED = 'blog_posts_loaded';
