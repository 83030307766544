import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import reportError from 'utils/reportError';

const handleApiCall = async (
  method: Method,
  url: string,
  data?: object,
  configOptions?: AxiosRequestConfig
): Promise<AxiosResponse> => {
  const config = {
    method,
    url,
    data,
    ...configOptions,
  };
  try {
    const response = await axios(config);
    return response;
  } catch (error) {
    const err = error as AxiosError;
    if (err.response?.status === 401 && !window?.location?.port) {
      localStorage.setItem('requestOnRedirect', JSON.stringify(config));
      if (window) {
        // TS workaround https://github.com/microsoft/TypeScript/issues/48949
        const win: Window = window;
        win.location = `/auth/login?current=${encodeURIComponent(window.location.pathname)}`;
      }
    }
    if (err?.response?.status === 301 && window) {
      const redirectUri = err.response.data as string;
      window.location.href = redirectUri;
    }
    reportError(err, 'handleApiCall');
    throw err;
  }
};

export default handleApiCall;
