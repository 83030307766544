import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the Playlist and PlaylistsState types
export interface Playlist {
  id: string;
  name: string;
  // Add other playlist properties as needed
}

export interface PlaylistsState {
  usersPlaylists: Playlist[];
  setlistPlaylists: Playlist[];
  loading: boolean;
}

// Define the initial state
const initialState: PlaylistsState = {
  usersPlaylists: [],
  setlistPlaylists: [],
  loading: true,
};

// Create a slice
const playlistsSlice = createSlice({
  name: 'playlists',
  initialState,
  reducers: {
    updateUserPlaylists(state, action: PayloadAction<Playlist[]>) {
      state.usersPlaylists = action.payload;
      state.loading = false;
    },
    updateSetlistPlaylists(state, action: PayloadAction<Playlist>) {
      state.setlistPlaylists.push(action.payload);
      state.loading = false;
    },
    playlistsLoading(state) {
      state.loading = true;
    },
  },
});

// Export the actions and reducer
export const { updateUserPlaylists, updateSetlistPlaylists, playlistsLoading } =
  playlistsSlice.actions;
export default playlistsSlice.reducer;
