import * as Sentry from '@sentry/react';

const reportError = (err, message) => {
  if (window?.location.hostname.includes('ziki.fm')) {
    Sentry.captureException(err);
  } else {
    console.error(err, message);
  }
}

export default reportError;
