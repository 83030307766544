import loadable, { LoadableComponent } from '@loadable/component';

// // USER
const AddArtistPage = loadable(() => import('pages/AddArtistPage/AddArtistPage'));
const AddArtistRequest = loadable(() => import('pages/AddArtistPage/AddArtistRequest'));
const ArtistDiscovery = loadable(() => import('pages/ArtistDiscover'));
const ArtistJoinPage = loadable(() => import('pages/AddArtistPage/ArtistJoinPage'));
const ArtistPage = loadable(() => import('pages/ArtistPage/ArtistPage'));
const Concerts = loadable(() => import('pages/Concerts'));
const DiscoverPage = loadable(() => import('pages/DiscoverPage'));
const EventPage = loadable(() => import('pages/EventPage'));
const FavouritesPage = loadable(() => import('pages/YourFavourites/YourFavourites'));
const Homepage = loadable(() => import('pages/Homepage/Homepage'));
const Merch = loadable(() => import('pages/MerchPage'));
const News = loadable(() => import('pages/NewsPage'));
const OurMissionPage = loadable(() => import('pages/OurMissionPage'));
const ReportAnIssue = loadable(() => import('pages/SupportPage/ReportAnIssue'));
const SearchPage = loadable(() => import('pages/SearchPage'));
const SettingsPage = loadable(() => import('pages/SettingsPage/SettingsPage'));
const Support = loadable(() => import('pages/SupportPage'));
const UserAppreciationPage = loadable(() => import('pages/UserPage/UserAppreciationPage'));
const VenuePage = loadable(() => import('pages/VenuePage'));

interface RouteManifest {
  id: string;
  path: string | string[];
  component: LoadableComponent<any>;
  exact?: boolean;
  data?: Function;
}

export const routesManifest: RouteManifest[] = [
  {
    id: 'concertPage',
    path: '/concert/:eventId',
    component: EventPage,
  },
  {
    id: 'artistPage',
    path: '/artist/:pageid/:tab?',
    component: ArtistPage,
  },
  {
    id: 'venuePage',
    path: '/venue/:pageid',
    component: VenuePage,
  },
  {
    id: 'appreciationPage',
    path: '/appreciation/:userid',
    component: UserAppreciationPage,
  },
  {
    id: 'reportAnIssue',
    path: '/support/report-an-issue',
    component: ReportAnIssue,
  },
  { id: 'ArtistJoinPage', path: '/support/joining-as-an-artist', component: ArtistJoinPage },
  { id: 'Support', path: '/support', component: Support },
  { id: 'ourMission', path: '/our-mission', component: OurMissionPage },
  { id: 'settings', path: '/settings', component: SettingsPage },
  { id: 'artistDiscovery', path: '/artists', component: ArtistDiscovery },
  { id: 'merch', path: '/merch', component: Merch },
  { id: 'news', path: '/news', component: News },
  { id: 'concerts', path: '/concerts', component: Concerts },
  { id: 'discover', path: '/discover', component: DiscoverPage },
  {
    id: 'search',
    path: ['/search/:searchterm', '/search', '/search/'],
    component: SearchPage,
  },
  { id: 'addArtist', path: '/add-artist/artist-details', component: AddArtistPage },
  { id: 'addArtistRequest', path: '/add-artist', component: AddArtistRequest },
  { id: 'artistDiscovery', path: '/artists', component: ArtistDiscovery },
  { id: 'homepage', path: '/', component: Homepage, exact: true },
  {
    id: 'favouritesPage',
    path: '/your-favourites/:tab?',
    component: FavouritesPage,
  },
  {
    id: 'blogPost',
    path: '/blog/:postLink',
    component: loadable(() => import('pages/Blog/BlogPost')),
  },
  {
    id: 'blogPosts',
    path: '/blog',
    component: loadable(() => import('pages/Blog/BlogList')),
  },
  {
    id: 'artistJoin',
    path: '/artist-join',
    component: loadable(() => import('pages/ArtistJoinPage/ArtistJoinPage')),
  },
];

interface RedirectManifest {
  id: string;
  from: string;
  to: string;
}

export const redirects: RedirectManifest[] = [{ id: 'contact', from: '/contact', to: '/support' }];
