import { ARTIST_LOADING, ARTIST_LOADED } from '../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case ARTIST_LOADING:
      return { artist: {}, loading: true };
    case ARTIST_LOADED:
      return { ...action.data, loading: false };
    default:
      return state;
  }
}
