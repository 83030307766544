import { SETLISTS_LOADED, SETLISTS_LOADING, SETLISTS_MORE } from '../actions/types';

const initialState = {
  setlists: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SETLISTS_LOADED:
      return { ...state, setlists: action.data, loading: false };
    case SETLISTS_LOADING:
      return { ...state, loading: true };
    case SETLISTS_MORE:
      return { ...state, setlists: [...state.setlists, ...action.data] };
    default:
      return state;
  }
}
