import {
  CONCERT_ATTENDING_UPDATED,
  CONCERT_DETAILS_LOADING,
  CONCERT_DETAILS_LOADED,
} from '../actions/types';

const initial = {
  loading: true,
};

export default function (state = initial, action) {
  switch (action.type) {
    case CONCERT_DETAILS_LOADING:
      return { loading: true };
    case CONCERT_ATTENDING_UPDATED:
      return { ...state, attending: action.data };
    case CONCERT_DETAILS_LOADED:
      return action.data;
    default:
      return state;
  }
}
