import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import { createEmotionCache } from '../server/ssr/createEmotionCache';

import App from './App';

import * as Sentry from '@sentry/react';

import { hasWindow } from 'utils/withWindow';

import { theme } from './Theme';
import { BrowserRouter } from 'react-router-dom';
import { storeInit } from './store-init';
import { loadableReady } from '@loadable/component';
import { HelmetProvider } from 'react-helmet-async';

const preloadedState = window.REDUX_DATA;

delete window.REDUX_DATA;
const store = storeInit(preloadedState);
const cache = createEmotionCache();

const Main = () => {
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    if (hasWindow && window?.location?.hostname?.includes('ziki.fm')) {
      Sentry.init({
        dsn: 'https://d6b2edb673354de3bd77550057b7a61e@o347191.ingest.sentry.io/2105085',
        integrations: [Sentry.browserTracingIntegration()],
        tracesSampleRate: 0.1,
        environment: location.hostname.startsWith('ziki.fm') ? 'production' : 'staging',
      });
    }
  }, []);

  return (
    <Sentry.ErrorBoundary fallback={'An error has occurred'} showDialog>
      <Provider store={store}>
        <CacheProvider value={cache}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <HelmetProvider>
                <App />
              </HelmetProvider>
            </BrowserRouter>
          </ThemeProvider>
        </CacheProvider>
      </Provider>
    </Sentry.ErrorBoundary>
  );
};

loadableReady(() => {
  ReactDOM.hydrateRoot(document.getElementById('app'), <Main />);
});
