import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the BlogPost and BlogState types
interface BlogPost {
  id: number;
  title: string;
  content: string;
}

interface BlogState {
  loading: boolean;
  posts: BlogPost[];
}

// Define the initial state
const initialState: BlogState = {
  loading: false,
  posts: [],
};

// Create a slice
const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    blogPostsLoading(state) {
      state.loading = true;
    },
    blogPostsLoaded(state, action: PayloadAction<BlogPost[]>) {
      state.loading = false;
      state.posts = [...state.posts, ...action.payload];
    },
  },
});

// Export the actions and reducer
export const { blogPostsLoading, blogPostsLoaded } = blogSlice.actions;
export default blogSlice.reducer;
