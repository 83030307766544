import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Skeleton } from '@mui/material';
import { styled } from '@mui/system';

const BorderLinearProgress = styled(LinearProgress)(() => ({
  root: {
    height: 10,
    borderRadius: 5,
    background: '#424242',
  },
  bar: {
    borderRadius: 5,
  },
  bar2Buffer: {
    borderRadius: 5,
    background: 'orange',
  },
  dashed: {
    display: 'none',
  },
}));

interface Props {
  min: number;
  max: number;
  value: number;
  valueBuffer?: number;
  level: number;
  compact?: boolean;
}

const LinearProgressWithLabel = ({ min, max, value, valueBuffer, level, compact }: Props) => {
  if (!value) {
    return <Skeleton height={compact ? '1.75rem' : '106px'} width={compact ? 100 : '100%'} />;
  }

  const normalise = (value: number) => ((value - min) * 100) / (max - min);

  return (
    <div id="appreciation-level">
      {!compact && (
        <Typography variant="h6" component="h3">
          Appreciation Level - Level {level}
        </Typography>
      )}
      <Box textAlign="center" width="100%" mr={1} mt={compact ? 0 : 2} mb={compact ? 0 : 2}>
        {compact ? (
          <Typography
            variant="caption"
            title={`Level ${level} - ${value} points. ${max - value} points to go for level ${
              level + 1
            }`}
          >
            Level {level} - {value}
          </Typography>
        ) : (
          <Typography variant="caption">{value} points</Typography>
        )}
        <BorderLinearProgress
          color="secondary"
          variant="determinate"
          value={normalise(value) || 0}
          valueBuffer={valueBuffer ? normalise(valueBuffer) : 0}
        />
        {!compact && (
          <Typography variant="caption">
            {max - value} points to go for level {level + 1}
          </Typography>
        )}
      </Box>
    </div>
  );
};

export default LinearProgressWithLabel;
