import {
  ADDREMOVE_FAVOURITE,
  CLEAR_USER,
  FETCH_USER,
  LOADING_USER,
  SPOTIFY_AUTH,
  SPOTIFY_CALLBACK,
  SPOTIFY_REFRESH,
  SPOTIFY_TOPARTISTS,
  UPDATE_SETTINGS,
} from '../actions/types';

export default function (state = false, action) {
  switch (action.type) {
    case LOADING_USER:
      return { ...state, loading: true };
    case FETCH_USER:
      return { ...state, ...action.payload, loading: false } || false;
    case UPDATE_SETTINGS:
      return {
        ...state,
        ...action.data.user,
        status: action.data.status,
        message: action.data.message,
      };
    case ADDREMOVE_FAVOURITE:
      return Object.assign({}, state, { favourites: action.payload.data });
    case CLEAR_USER:
      return false;
    case SPOTIFY_AUTH:
      return Object.assign({}, state, { spotifyauth: action.payload.data });
    case SPOTIFY_CALLBACK:
      return Object.assign({}, state, { spotifycallback: action.payload.data });
    case SPOTIFY_REFRESH:
      return Object.assign({}, state, { spotifyrefresh: action.payload.data });
    case SPOTIFY_TOPARTISTS:
      return Object.assign({}, state, { spotifytopartists: action.payload.data });

    default:
      return state;
  }
}
