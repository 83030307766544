import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the NewsArticle and NewsReducerState types
interface NewsArticle {
  title: string;
  openInNewTab?: boolean;
}

interface NewsReducerState {
  loading: boolean;
  articles: NewsArticle[];
  feeds?: NewsArticle[];
  feedsLoading?: boolean;
}

// Define the initial state
const initialState: NewsReducerState = {
  loading: false,
  articles: [],
};

// Create a slice
const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    newsLoading(state) {
      state.loading = true;
      state.articles = [];
    },
    newsLoaded(state, action: PayloadAction<NewsArticle[]>) {
      state.loading = false;
      state.articles = action.payload;
    },
    newsMore(state, action: PayloadAction<NewsArticle[]>) {
      state.articles = [...state.articles, ...action.payload];
    },
    newsFeeds(state, action: PayloadAction<NewsArticle[]>) {
      state.feeds = action.payload;
      state.feedsLoading = false;
    },
  },
});

// Export the actions and reducer
export const { newsLoading, newsLoaded, newsMore, newsFeeds } = newsSlice.actions;
export default newsSlice.reducer;
