import { GET_ADMIN_ARTIST, ARTISTUPDATE } from '../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case GET_ADMIN_ARTIST:
      return { ...state, artist: action.data || {} };
    case ARTISTUPDATE:
      return { ...state, message: action.payload.data };
    default:
      return state;
  }
}