import { createTheme } from '@mui/material/styles';
import { orange, cyan, amber } from '@mui/material/colors';

export const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: amber,
    secondary: cyan,
  },
  typography: {
    fontFamily: ['Helvetica', 'Arial', 'sans-serif'].join(','),
  },
});
