import { useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

const tabRoutes = ['/artist/:artist/:tab', '/user/:user/:tab'];

export default function ScrollToTop() {
  const { pathname } = useLocation();

  const matchRoute = useRouteMatch(tabRoutes);

  useEffect(() => {
    if (window && !matchRoute?.params?.tab) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant',
      });
    }
  }, [pathname]);

  return null;
}
