import { combineReducers } from 'redux';

import AdminReducer from './admin';
import AppreciationReducer from './reducer_appreciation';
import ArtistReducer from './artist';
import BlogReducer from './blog';
import CheckoutReducer from './checkout';
import ConcertDetailsReducer from './concertDetails';
import ErrorsReducer from './reducer_errors';
import EventsReducer from './events';
import FavouritesReducer from './reducer_favourites';
import MerchReducer from './merch';
import NewsReducer from './news';
import PlaylistReducer from './playlists';
import ProductReducer from './product';
import SearchReducer from './reducer_search';
import SelectedUserReducer from './reducer_selecteduser';
import SetlistsReducer from './setlists';
import UserReducer from './reducer_user';

// STATIC REDUCER
const rootReducer = combineReducers({
  admin: AdminReducer,
  appreciation: AppreciationReducer,
  artist: ArtistReducer,
  blog: BlogReducer,
  checkout: CheckoutReducer,
  concertDetails: ConcertDetailsReducer,
  errors: ErrorsReducer,
  events: EventsReducer,
  favourites: FavouritesReducer,
  merch: MerchReducer,
  news: NewsReducer,
  playlists: PlaylistReducer,
  product: ProductReducer,
  search: SearchReducer,
  setlists: SetlistsReducer,
  selectedUser: SelectedUserReducer,
  user: UserReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
