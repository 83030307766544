import axios from 'axios';

import {
  CLEAR_USER,
  FETCH_USER,
  REMOVE_NEW_BADGE,
  SELECTED_USER,
  SETTINGS_PROCESSING,
  UPDATE_APPRECIATION,
  UPDATE_FAVOURITES,
  UPDATE_SETTINGS,
  UPDATE_USER_PLAYLISTS,
  LOADING_USER,
  GET_USER_CONCERTS,
} from 'actions/types';

import reportError from 'utils/reportError';
import handleApiCall from './handleApiCall';

const processPreAuthRequests = async () => {
  const requestOnRedirect = localStorage.getItem('requestOnRedirect');
  localStorage.removeItem('requestOnRedirect');

  if (requestOnRedirect && requestOnRedirect !== 'null') {
    const parsedRequest = JSON.parse(requestOnRedirect);
    if (parsedRequest?.method && parsedRequest?.url) {
      await handleApiCall(parsedRequest.method, parsedRequest.url, parsedRequest.data);
    }
  }
};

export const getCurrentUser = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING_USER });
    const { data: user } = await axios.get(`/api/user`);

    if (user) {
      dispatch({ type: FETCH_USER, payload: user });

      await processPreAuthRequests();

      const [
        { data: favourites },
        { data: appreciation },
        { data: playlists },
        { data: userConcerts },
      ] = await Promise.all([
        handleApiCall('get', `/api/favourites`),
        handleApiCall('get', `/api/user/appreciation`),
        handleApiCall('get', '/api/playlists/user'),
        handleApiCall('get', '/api/events/userConcerts'),
      ]);
      if (favourites) {
        dispatch({ type: UPDATE_FAVOURITES, data: favourites });
      }
      if (appreciation) {
        dispatch({ type: UPDATE_APPRECIATION, data: appreciation });
      }
      if (playlists) {
        dispatch({ type: UPDATE_USER_PLAYLISTS, data: playlists });
      }
      if (userConcerts) {
        dispatch({ type: GET_USER_CONCERTS, data: userConcerts });
      }
    } else {
      dispatch({ type: CLEAR_USER });
    }
  } catch (err) {
    console.log('Error getting user', err);
  }
};

export const addFavourite = (id) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_FAVOURITES, loading: true });
    const response = await handleApiCall('get', `/api/addfavourite?id=${id}`);
    if (response?.data) {
      dispatch({
        type: UPDATE_FAVOURITES,
        loading: false,
        data: response.data,
      });
      const { data: appreciation } = await axios.get(`/api/user/appreciation`);
      if (appreciation) {
        dispatch({ type: UPDATE_APPRECIATION, data: appreciation });
      }
    } else {
      dispatch({
        type: UPDATE_FAVOURITES,
        loading: false,
        error: 'There was an unknown issue',
      });
    }
  } catch (err) {
    reportError(err);
    dispatch({ type: UPDATE_FAVOURITES, loading: false });
  }
};

export const removeFavourite = (id) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_FAVOURITES, loading: true });
    const response = await axios.get(`/api/removefavourite?id=${id}`);
    if (response?.data) {
      dispatch({
        type: UPDATE_FAVOURITES,
        loading: false,
        data: response.data,
      });
      const { data: appreciation } = await axios.get(`/api/user/appreciation`);
      if (appreciation) {
        dispatch({ type: UPDATE_APPRECIATION, data: appreciation });
      }
    } else {
      dispatch({
        type: UPDATE_FAVOURITES,
        loading: false,
        error: 'There was an unknown issue',
      });
    }
  } catch (err) {
    reportError(err);
    dispatch({ type: UPDATE_FAVOURITES, loading: false });
  }
};

export const updateFavourites = (favourites) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_FAVOURITES, loading: true });
    const response = await handleApiCall('post', `/api/updateFavourites`, favourites);
    if (response?.data) {
      dispatch({
        type: UPDATE_FAVOURITES,
        loading: false,
        data: response.data,
      });
      const { data: appreciation } = await axios.get(`/api/user/appreciation`);
      if (appreciation) {
        dispatch({ type: UPDATE_APPRECIATION, data: appreciation });
      }
    } else {
      dispatch({
        type: UPDATE_FAVOURITES,
        loading: false,
        error: 'There was an unknown issue',
      });
    }
  } catch (err) {
    reportError(err);
    dispatch({ type: UPDATE_FAVOURITES, loading: false });
  }
};

export const closeBadge = (id) => async (dispatch) =>
  dispatch({ type: REMOVE_NEW_BADGE, data: id });

export const logout = () => async (dispatch) => {
  try {
    await axios.get(`/auth/logout`);
    dispatch({ type: CLEAR_USER });
    return true;
  } catch (err) {
    console.log(err);
    // TODO: handle logout error
    return false;
  }
};

export const updateSettings = (values) => async (dispatch) => {
  try {
    dispatch({ type: SETTINGS_PROCESSING, data: true });
    const response = await axios.patch(`/api/updateSettings`, values);

    if (response?.data) {
      dispatch({ type: UPDATE_SETTINGS, data: response.data });
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getSelectedUser = (username) => async (dispatch) => {
  const { data: selectedUser } = await axios.get(`/api/selectedUser?username=${username}`);
  if (selectedUser) {
    dispatch({ type: SELECTED_USER, data: selectedUser });
  }
};

export const createAndAddFavourite = (data) => async (dispatch) => {
  dispatch({ type: UPDATE_FAVOURITES, loading: true });
  const response = await handleApiCall('post', `/api/createAndAddFavourite`, data);
  if (response?.data) {
    dispatch({
      type: UPDATE_FAVOURITES,
      loading: false,
      data: response.data,
    });
    const { data: appreciation } = await handleApiCall('get', `/api/user/appreciation`);
    if (appreciation) {
      dispatch({ type: UPDATE_APPRECIATION, data: appreciation });
    }
  } else {
    dispatch({
      type: UPDATE_FAVOURITES,
      loading: false,
      error: 'There was an unknown issue',
    });
  }
};

export const updateGuestFavourites = (data) => async (dispatch) => {
  dispatch({ type: UPDATE_FAVOURITES, loading: true });
  const sortedData = {
    artists: data.map((item, i) => ({
      ...item,
      sort_order: i,
    })),
  };
  dispatch({
    type: UPDATE_FAVOURITES,
    loading: false,
    data: sortedData,
  });
};

export const getUserAppreciationHistory = async () => {
  const { data } = await axios.get(`/api/user/appreciation-history`);
  return data;
};
