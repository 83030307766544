import {
  EVENTS_LOADING,
  EVENTS_LOADED,
  EVENTS_MORE,
  EVENTS_MAP,
  GET_USER_CONCERTS,
} from '../actions/types';

const initialState = {
  events: [],
  loading: false,
  map: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case EVENTS_LOADING:
      return { ...state, events: [], loading: true };
    case EVENTS_LOADED:
      return { ...state, events: action.data, loading: false };
    case EVENTS_MORE:
      return { ...state, events: [...state.events, ...action.data] };
    case EVENTS_MAP:
      return { ...state, map: action.data };
    case GET_USER_CONCERTS:
      return { ...state, userConcerts: action.data };
    default:
      return state;
  }
}
