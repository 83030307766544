import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import { closeBadge } from 'actions/user';
import { Box } from '@mui/material';

const classes = {
  root: (theme) => ({
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  }),
};

const BadgeNotification = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const { badges } = useSelector((state) => state?.favourites);
  // const badges = {
  //   all: [
  //     {
  //       id: 1,
  //       title: 'Account created',
  //       image: 'original-badge.png',
  //       date: '2022-03-20T23:03:06.000Z',
  //       description: 'You earnt this badge for creating your account.',
  //     },
  //   ],
  //   new: [['', 1]],
  // };

  useEffect(() => {
    if (badges?.new?.length) {
      setOpen(true);
    }
  }, [badges]);

  const handleClose = (event, reason, id) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    dispatch(closeBadge(id));
  };

  if (!badges?.all?.length || !badges?.new?.length) {
    return null;
  }

  const [, badgeId] = badges.new[0];
  const badge = badges.all.find((badge) => badge.id === badgeId);

  return (
    <Box sx={classes.root}>
      <Snackbar
        open={open}
        onClose={(e, r) => handleClose(e, r, badge.id)}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        autoHideDuration={6000}
      >
        <SnackbarContent
          sx={{ border: 2, borderColor: 'secondary.main' }}
          message={`Badge unlocked: ${badge.title} | +100 points`}
        />
      </Snackbar>
    </Box>
  );
};

export default BadgeNotification;
