import { ERROR_LOGIN, ERROR_REGISTER } from '../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case ERROR_LOGIN:
      return {
        ...state,
        login: action.payload.data
      };
    case ERROR_REGISTER:
      return {
        ...state,
        register: action.payload.data
      };
    default:
      return state;
  }
}