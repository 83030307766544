import { UPDATE_APPRECIATION, CLEAR_USER } from '../actions/types';

export default function (state = false, action) {
  switch (action.type) {
    case UPDATE_APPRECIATION:
      return action.data;
    case CLEAR_USER:
      return {};
    default:
      return state;
  }
}