import { configureStore, StateFromReducersMapObject } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import reducers from './reducers';
import { useDispatch, useSelector, useStore } from 'react-redux';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export type RootState = StateFromReducersMapObject<typeof reducers>;

export const storeInit = (initialState?: Partial<RootState>) =>
  configureStore({
    reducer: reducers,
    preloadedState: initialState,
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sentryReduxEnhancer),
    devTools: true,
  });

type Store = ReturnType<typeof storeInit>;

export type AppStore = Store;
export type AppDispatch = Store['dispatch'];
export type AppGetState = Store['getState'];

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
export const useAppStore = useStore.withTypes<AppStore>();
