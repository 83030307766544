import { MERCH_LOADING, MERCH_LOADED, MERCH_MORE, FILTERED_MERCH_LOADED, FILTERED_MERCH_LOADING } from '../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case MERCH_LOADING:
      return { ...state, products: [], loading: true };
    case MERCH_LOADED:
      return { ...state, ...action.data, loading: false };
    case MERCH_MORE:
      return { ...state, products: [...state.products, ...action.data.products] };
    case FILTERED_MERCH_LOADING:
      return { ...state, products: [], loading: true } // preserve categories etc
    case FILTERED_MERCH_LOADED:
      return { ...state, ...action.data, loading: false }
    default:
      return state;
  }
}